// .env constants
const {
    REACT_APP_ENV,
    REACT_APP_VERSION,

    REACT_APP_APP_NAME,
    REACT_APP_URL,
    REACT_APP_API_URL,

    REACT_APP_BUGSNAG_KEY,
    REACT_APP_GOOGLE_MAPS_KEY,
    OAUTH_ISSUER_URL,
    OAUTH_AUTH_URL,
    OAUTH_CLIENT_ID,
    OAUTH_CLIENT_SECRET,
    OAUTH_REDIRECT_URL,
    AUTH_HEADER,
} = process.env;

// APP CONSTANTS
const app = {
    ENV: REACT_APP_ENV,
    VERSION: REACT_APP_VERSION || '0.0.0',
    DEBUG: REACT_APP_ENV === 'local',
    MOCK: false,
    BACKEND: 'laravel',

    APP_NAME: REACT_APP_APP_NAME || 'Welbeck Health',

    APP_URL: REACT_APP_URL,
    API_URL: REACT_APP_API_URL,

    BUGSNAG_KEY: REACT_APP_BUGSNAG_KEY,
    GOOGLE_MAPS_KEY: REACT_APP_GOOGLE_MAPS_KEY,

    AUTH_KEY: 'auth-key',
    LOCALSTORAGE_EXPIRES_KEY: 'session-token-expiry',
    LOCALSTORAGE_EXPIRES_TIME: '30', // minutes
    OAUTH_ISSUER_URL,
    OAUTH_AUTH_URL,
    OAUTH_CLIENT_ID,
    OAUTH_CLIENT_SECRET,
    OAUTH_REDIRECT_URL,
    AUTH_HEADER,
};

export default app;
