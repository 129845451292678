/* eslint-disable quote-props, import/named */

import { put, call, take, takeEvery, all, actionChannel, flush } from 'redux-saga/effects';

import moment from 'moment';
import { message } from 'antd';
import { fetchApi } from '../utils/api';

import {
    postAvailabilityRequest,
    postAvailabilitySuccess,
    postAvailabilityFailure,
} from './availabilityActions';
import { makeUrl, REDUX_DATE_FORMAT } from '../utils/helpers';

const {
    POST_AVAILABILITY_REQUEST,
    POST_CALENDAR_AVAILABILITY_REQUEST,
} = require('./availabilityActions').constants;

let availabilityChannel = null;

function* fetchAvailability({ availabilityPayload }) {
    const { ServicesRequired, StartDate, EndDate } = availabilityPayload;
    const getAvailabilityUrl = makeUrl('availabilities/getAvailability', {
        sDate: StartDate,
        eDate: EndDate,
        serviceRequestId: ServicesRequired,
    });

    const request = {
        method: 'GET',
        url: getAvailabilityUrl,
        version: 'v2',
    };

    return yield call(fetchApi, request);
}

function* fetchPostAvailabilityRequest({ payload }) {
    try {
        const { startDate, serviceId } = payload;
        const endDate = moment(startDate).clone().add(4, 'w');
        const availabilityPayload = {
            'StartDate': moment(startDate).format(REDUX_DATE_FORMAT),
            'EndDate': endDate.format(REDUX_DATE_FORMAT),
            'ServicesRequired': serviceId,
        };
        const [availabilityResponse] = yield all([
            call(fetchAvailability, { availabilityPayload }),
        ]);

        if (availabilityResponse?.data[0]?.Data[0]?.Resources === null) {
            yield put(postAvailabilityFailure('No availability'));
            return;
        }

        yield put(postAvailabilitySuccess({
            serviceId,
            startDate,
            availability: availabilityResponse?.data[0]?.Data[0] || {},
        }));
    } catch (e) {
        message.error('Error', e.response?.data?.message || 'Something went wrong');
        if (availabilityChannel) {
            yield flush(availabilityChannel);
        }
        yield put(postAvailabilityFailure(e.response ? e.response.data.message : e));
    }
}

function* postCalendarAvailabilityRequest({ payload }) {
    yield put(postAvailabilityRequest({
        ...payload,
    }));
}

function* watchAvailabilityRequests() {
    availabilityChannel = yield actionChannel(POST_AVAILABILITY_REQUEST);

    // eslint-disable-next-line no-constant-condition
    while (true) {
        const payload = yield take(availabilityChannel);
        yield call(fetchPostAvailabilityRequest, payload);
    }
}

export default function* availabilitySagas() {
    yield* [
        takeEvery(POST_CALENDAR_AVAILABILITY_REQUEST, postCalendarAvailabilityRequest),
        watchAvailabilityRequests(),
    ];
}
