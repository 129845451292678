import { put, call, takeEvery } from 'redux-saga/effects';

import { fetchApi } from '../utils/api';

import {
    postBookingSuccess,
    postBookingFailure,

    getPatientBookingsDetailsSuccess,
    getPatientBookingsDetailsFailure,
} from './bookingsActions';

const {
    POST_BOOKING_REQUEST,
    GET_PATIENT_BOOKINGS_DETAILS_REQUEST,
} = require('./bookingsActions').constants;

function* postBookingRequest({ payload }) {
    const { bookingRequests } = payload;

    try {
        const responses = yield call(fetchApi, {
            method: 'POST',
            url: 'serviceRequests/postBookService',
            body: JSON.stringify(bookingRequests),
        });
        yield put(postBookingSuccess(responses?.data?.Data));
    } catch (e) {
        yield put(postBookingFailure(e.response ? e.response.data.message : 'Something went wrong'));
    }
}

function* getPatientBookingsDetails({ payload }) {
    const { messageId, methodToBook, surname, dob } = payload;

    const dobFormatted = dob.split('/').reverse().join('-');

    try {
        const response = yield call(fetchApi, {
            method: 'GET',
            url: `patients/CheckPatientDetails?messageId=${messageId}&methodToBook=${methodToBook}&surname=${surname}&dob=${dobFormatted}`,
        });

        yield put(getPatientBookingsDetailsSuccess(response?.data));
    } catch (e) {
        yield put(getPatientBookingsDetailsFailure(e.response ? e.response.data.message : e));
    }
}

export default function* clinicianSagas() {
    yield* [
        takeEvery(POST_BOOKING_REQUEST, postBookingRequest),
        takeEvery(GET_PATIENT_BOOKINGS_DETAILS_REQUEST, getPatientBookingsDetails),
    ];
}
