/* eslint-disable default-param-last */
/* eslint-disable no-fallthrough */
import { Record } from 'immutable';
import moment from 'moment';
import { find, findKey, forEach, isEmpty, isNaN, isUndefined } from 'underscore';

import { REDUX_DATE_FORMAT } from '../utils/helpers';
import { serviceOpeningHours, LIMITED_AVAILABILITY_THRESHOLD } from '../utils/values';

const {
    POST_AVAILABILITY_REQUEST,
    POST_AVAILABILITY_SUCCESS,
    POST_AVAILABILITY_FAILURE,

    SET_SELECTED_SLOT,
} = require('./availabilityActions').constants;

const InitialState = Record({
    selectedSlots: {},
    availabilitySlots: {},
    firstAvailableSlot: {},
    availabilityDetail: {},
    resourceValues: {},
    isFetching: false,
    error: null,
});

export const initialState = new InitialState();

/**
 * Determines the availability status based on available slot count.
 *
 * @param {number} availableSlotCount - The number of available slots.
 *
 * @returns {string} The availability status.
 */
const getAvailability = (availableSlotCount) => {
    if (availableSlotCount === 0) {
        return 'unavailable';
    }

    if (availableSlotCount < LIMITED_AVAILABILITY_THRESHOLD) {
        return 'limited';
    }

    return 'available';
};

/**
 * Creates a structure with time slots based on open hours, start time and slot duration.
 *
 * @param {Object} rangeAvailabilitySlots - An object with date as keys and an object as value.
 * @param {moment} startTime - The start time for which to calculate the time slots.
 * @param {moment} endTime - The end time for which to calculate the time slots.
 * @param {number} interval - The interval in minutes.
 *
 * @returns {Object} An object with date as keys and an object as value, which includes availability and slots.
 */
const getTimeSlotsFromRange = (rangeAvailabilitySlots, startTime, endTime, interval = 15) => {
    if (isNaN(interval) || typeof interval !== 'number') {
        throw new Error('interval must be a number');
    }

    let availableSlotCount = 0;
    const timeRanges = [];
    while (startTime.isBefore(endTime)) {
        const slotDate = startTime.clone().format(REDUX_DATE_FORMAT);
        const slotTime = startTime.clone().format('HH:mm');
        const isAvailable = !isUndefined(rangeAvailabilitySlots?.[slotDate]?.[slotTime]);
        const { resources } = rangeAvailabilitySlots?.[slotDate]?.[slotTime] ?? {};

        if (isAvailable) {
            availableSlotCount += 1;
        }

        timeRanges.push({
            startTime: startTime.clone().format('HH:mm'),
            isCheckAvailability: false,
            isAvailable,
            resources,
        });
        startTime.add(interval, 'minutes');
    }

    return {
        availability: getAvailability(availableSlotCount),
        slots: timeRanges,
    };
};

const repeatOpeningHours = (openingHours, times) => {
    const repeated = [];
    for (let i = 0; i < times; i++) {
        repeated.push(...openingHours.map((hour) => `${hour} (Week ${i + 1})`));
    }
    return repeated;
};

/**
 * Creates a structure with business days and corresponding available timeslots based on open hours, start date and slot duration.
 *
 * @param {Array} availableSlots - An array of available time slots.
 * @param {Array} openingHours - An array of opening hours for each business day.
 * @param {string} startDate - The start date for which to calculate the time slots.
 * @param {number} slotDuration - The duration of each slot in minutes.
 *
 * @returns {Object} An object with date as keys and an object as value, which includes slotInterval and slots.
 */
const getTimeSlots = (availableSlots, openingHours, startDate, slotDuration) => {
    const repeatedOpeningHours = repeatOpeningHours(openingHours, 4);
    return repeatedOpeningHours.reduce((acc, curr, index) => {
        const [, startTime, endTime] = curr.split(' - ');
        const weekday = moment(startDate, REDUX_DATE_FORMAT).add(index, 'days').format(REDUX_DATE_FORMAT);
        const startMoment = moment(`${weekday} ${startTime}`, 'YYYY-MM-DD HH:mm');
        const endMoment = moment(`${weekday} ${endTime}`, 'YYYY-MM-DD HH:mm');

        if (!endTime) {
            return { ...acc };
        }

        const slots = getTimeSlotsFromRange(availableSlots, startMoment, endMoment, slotDuration);

        return {
            ...acc,
            [weekday]: {
                slotDuration,
                ...slots,
            },
        };
    }, {});
};

/**
 * Gets the first available slot from the available slots.
 *
 * @param {Object} availabilitySlots - An object with date as keys and an object as value.
 *
 * @returns {Object} An object with date and slot as keys.
 */
const getFirstAvailableSlot = (availabilitySlots) => {
    let firstAvailableSlot = null;
    let isCheckAvailability = false;
    let resources = [];
    let isAvailable = false;
    const firstAvailableDate = findKey(availabilitySlots, (slots) => !isEmpty(find(slots.slots, (slot) => {
        if (slot.isAvailable) {
            firstAvailableSlot = slot.startTime;
            isCheckAvailability = slot.isCheckAvailability;
            resources = slot.resources;
            isAvailable = slot.isAvailable;
        }

        return slot.isAvailable;
    })));

    if (!firstAvailableDate) {
        return null;
    }

    return {
        slot: firstAvailableSlot,
        date: firstAvailableDate,
        isCheckAvailability,
        resources,
        isAvailable,
    };
};

export default function availabilityReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    let tempResourceValues = {};

    const getAvailableSlots = (resources) => {
        const availableSlots = {};
        forEach(resources, resource => {
            const { Sessions: sessions, ...resourceDetails } = resource;
            const { CCResourceID: resourceId, ResourceName: resourceName, ResourceType: resourceType } = resourceDetails;

            tempResourceValues = {
                ...tempResourceValues,
                ...state.resourceValues,
                [resourceId]: {
                    resourceId,
                    resourceName,
                    resourceType,
                },
            };

            const slots = sessions?.flatMap(session => session.Slots);

            forEach(slots, slot => {
                const { SlotDate, SlotTime } = slot;
                const startTime = moment(`${SlotDate} ${SlotTime}`, 'YYYY-MM-DD HH:mm:ss', true).format('HH:mm');

                availableSlots[SlotDate] = {
                    ...availableSlots?.[SlotDate] || {},
                    [startTime]: {
                        resources: [
                            ...availableSlots?.[SlotDate]?.[startTime]?.resources ?? [],
                            resourceId,
                        ],
                    },
                };
            });
        });

        return availableSlots;
    };

    switch (type) {

    case SET_SELECTED_SLOT: {
        const { serviceId, ...selectedSlot } = payload;
        let newSelectedSlots = {};

        if (serviceId) {
            newSelectedSlots = {
                ...state.selectedSlots,
                [serviceId]: selectedSlot,
            };
        }
        return state.set('selectedSlots', newSelectedSlots);
    }

    case POST_AVAILABILITY_REQUEST:
        return state
            .set('isFetching', true)
            .set('error', null);

    case POST_AVAILABILITY_SUCCESS: {
        const { serviceId, startDate, availability, clinicianHours } = payload;
        const {
            Resources: resources = [],
            ...availabilityDetails
        } = availability || {};
        const getFirstSlotInterval = (resourcesItem) => {
            if (!Array.isArray(resourcesItem) || resourcesItem?.length === 0) return null;
            const firstResource = resourcesItem[0];
            if (firstResource.Sessions?.length === 0) return null;
            return firstResource.Sessions[0].SlotInterval;
        };

        const slotDuration = getFirstSlotInterval(resources) || 30;

        const serviceTimeSlots = getTimeSlots(getAvailableSlots(resources), serviceOpeningHours, startDate, slotDuration);

        forEach(clinicianHours, (clinicianHour) => {
            const { Date, TimeFrom, TimeTo } = clinicianHour?.Centre?.[0]?.doctor?.[0] || {};
            const timeFrom = moment(`${Date} ${TimeFrom}`, 'YYYY-MM-DD HH:mm:ss', true);
            const timeTo = moment(`${Date} ${TimeTo}`, 'YYYY-MM-DD HH:mm:ss', true);

            if (serviceTimeSlots[clinicianHour.Date]) {
                serviceTimeSlots[clinicianHour.Date] = serviceTimeSlots[clinicianHour.Date].map(timeSlot => {
                    const { startTime, isAvailable } = timeSlot;
                    const startMoment = moment(`${Date} ${startTime}`, 'YYYY-MM-DD HH:mm', true);
                    const isCheckAvailability = startMoment.isSameOrAfter(timeFrom) && startMoment.isBefore(timeTo);

                    return {
                        ...timeSlot,
                        isAvailable,
                        isCheckAvailability,
                    };
                });
            }
        });

        let newAvailabilitySlots = {};
        if (Object.keys(serviceTimeSlots)?.[0] < Object.keys(state.availabilitySlots)?.[0]) {
            newAvailabilitySlots = {
                ...serviceTimeSlots,
                ...state.availabilitySlots[serviceId],
            };
        } else {
            newAvailabilitySlots = {
                ...state.availabilitySlots[serviceId],
                ...serviceTimeSlots,
            };
        }

        // sort the keys so that the dates are in order (accounting for responses that come back out of order)
        newAvailabilitySlots = Object.keys(newAvailabilitySlots).sort().reduce((acc, key) => {
            acc[key] = newAvailabilitySlots[key];
            return acc;
        }, {});

        const existingFirstAvailableSlot = state.firstAvailableSlot[serviceId];

        let firstAvailableSlot = existingFirstAvailableSlot;
        if (isEmpty(existingFirstAvailableSlot)) {
            firstAvailableSlot = getFirstAvailableSlot(newAvailabilitySlots);
        }
        return state
            .set('availabilitySlots', {
                ...state.availabilitySlots,
                [serviceId]: newAvailabilitySlots,
            })
            .set('firstAvailableSlot', {
                ...state.firstAvailableSlot,
                [serviceId]: firstAvailableSlot,
            })
            .set('availabilityDetail', availabilityDetails)
            .set('resourceValues', tempResourceValues)
            .set('isFetching', false);
    }

    case POST_AVAILABILITY_FAILURE:
        return state
            .set('isFetching', false)
            .set('error', payload);
    default:
        return state;

    }
}
